import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Management Resources Consultants (MRC ASIA)',
      desc: 'Consulting in remuneration service, bridging data science and remuneration. HR data analytics, machine learning, predictive analytics, organisation right-sizing.',
    },
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Management Resources Consultants (MRC ASIA)',
      desc: 'Consulting in remuneration service, bridging data science and remuneration. HR data analytics, machine learning, predictive analytics, organisation right-sizing.',
    },
  },
  // {
  //   path: '/home2',
  //   name: 'Home2',
  //   component: () => import('../views/Home3.vue'),
  //   meta: {
  //     title: 'Management Resources Consultants (MRC ASIA)',
  //     desc: 'Consulting in remuneration service, bridging data science and remuneration. HR data analytics, machine learning, predictive analytics, organisation right-sizing.',
  //   },
  // },
  {
    path: '/member',
    name: 'MemberLogin',
    component: () => import('../views/Login.vue'),
    meta: {
      title: 'MRC - Member Login',
      desc: 'Member Login',
    },
  },
  {
    path: '/home/compensation_benchmarking',
    name: 'CompensationBenchmarking',
    component: () => import('../views/home/CompensationBenchmarking.vue'),
    meta: {
      title: 'MRC - Compensation Benchmarking',
      desc: 'Job evaluation, pay benchmarking and salary structure design',
    },
  },
  {
    path: '/home/executive_compensation',
    name: 'ExecutiveCompensation',
    component: () => import('../views/home/ExecComp.vue'),
    meta: {
      title: 'MRC - Executive Compensation Review & Recommendation Service',
      desc: 'Salary, bonus, benefits, stock and perks recommendation',
    },
  },
  {
    path: '/home/directors_remuneration',
    name: 'DirectorsRemuneration',
    component: () => import('../views/home/DirectorRemuneration.vue'),
    meta: {
      title: 'MRC - Board of Directors’ Remuneration Advisory',
      desc: 'Directors\' fees and remuneration package for board of directors powered by MRC listed companies\' database',
    },
  },
  {
    path: '/home/sales_incentive_plan',
    name: 'SalesIncentivePlanDesign',
    component: () => import('../views/home/SalesIncentivePlan.vue'),
    meta: {
      title: 'MRC - Sales Incentive Plan Design',
      desc: 'Commission, incentive, bonus and benefits',
    },
  },
  {
    path: '/home/stock_plan',
    name: 'StockPhantomStockPlanDesign',
    component: () => import('../views/home/StockPlan.vue'),
    meta: {
      title: 'MRC - Stock & Phantom Stock Plan Design',
      desc: 'Equity-settled / cash-settled share-based incentive plan design',
    },
  },
  {
    path: '/home/performance_appraisal',
    name: 'PerformanceAppraisal',
    component: () => import('../views/home/PerformanceAppraisal.vue'),
    meta: {
      title: 'MRC - Performance Appraisal and Bonus Distribution',
      desc: 'Restructure performance appraisal and bonus distribution approach',
    },
  },
  {
    path: '/home/relocation',
    name: 'Relocation',
    component: () => import('../views/home/Relocation.vue'),
    meta: {
      title: 'MRC - Relocation Package and Mobility Policy',
      desc: 'COLA, hardship, mobility, children education, housing, tax, localisation, pension, home passage and other mobility policies recommendation',
    },
  },
  {
    path: '/home/ma_compensation_benefits_integration',
    name: 'M&A Compensation and Benefits Integration',
    component: () => import('../views/home/MA.vue'),
    meta: {
      title: 'MRC - M&A Compensation and Benefits Integration',
      desc: 'Definitive agreement, equity compensation, employee compensation & benefits, integration assistance',
    },
  },
  {
    path: '/home/org_design',
    name: 'Organisation Design',
    component: () => import('../views/home/OrgDesign.vue'),
    meta: {
      title: 'MRC - Organisation Design',
      desc: 'Strategic organisation design and workforce planning powered by MRC benchmarking database & Job Evaluation System (JES)',
    },
  },
  {
    path: '/home/talent_management',
    name: 'Talent Management',
    component: () => import('../views/home/TalentManagement.vue'),
    meta: {
      title: 'MRC - Talent Management',
      desc: 'Attract, retain and develop your skilled talents. Powered by MRC A.I. Tools monitoring the talent trends across the globe.',
    },
  },
  {
    path: '/home/market_research',
    name: 'Market Research',
    component: () => import('../views/home/MarketResearch.vue'),
    meta: {
      title: 'MRC - Market Research',
      desc: 'Research on the right destination to start your new operation.',
    },
  },
  {
    path: '/tools',
    name: 'HR Tools & Data Services',
    component: () => import('../views/Tools.vue'),
    meta: {
      title: 'Management Resources Consultants (MRC ASIA)',
      desc: 'MRC Data Services and Super Tools, powered by A.I. & machine learning, provide a holistic view on latest HR & C&B market trends.',
    },
  },
  {
    path: '/tools/super_compensation',
    name: 'Super Compensation',
    component: () => import('../views/tools/SuperCompensation.vue'),
    meta: {
      title: 'MRC - Super Compensation',
      desc: 'Super Comp is a manifestation of the superintending of pay trends.',
    },
  },
  {
    path: '/tools/executive_compensation_private',
    name: 'Executive Compensation (Private)',
    component: () => import('../views/tools/ExecutiveCompensationSurvey.vue'),
    meta: {
      title: 'MRC - Executive Compensation Survey (Private)',
      desc: 'MRC Executive Compensation Survey for executives play a crucial role in their organisation',
    },
  },
  {
    path: '/tools/executive_compensation_public',
    name: 'Board of Directors’ Remuneration (Public Listed)',
    component: () => import('../views/tools/BoardOfDirectorsRemuneration.vue'),
    meta: {
      title: 'MRC - Board of Directors’ Remuneration (Public Listed)',
      desc: 'Benchmarking Board of Directors’ Pay for listed companies in APAC',
    },
  },
  {
    path: '/tools/sale_incentive_report',
    name: 'Sales Incentive Market Practices Report',
    component: () => import('../views/tools/SalesIncentiveMarketPracticesReport.vue'),
    meta: {
      title: 'Sales Incentive Market Practices Report',
      desc: 'Getting the salesforce hungry for more sales is the aim of every company.',
    },
  },
  {
    path: '/tools/expat_practice_report',
    name: 'Expatriate Practice Report',
    component: () => import('../views/tools/ExpatriatePracticeReport.vue'),
    meta: {
      title: 'Expatriate Practice Report',
      desc: 'Expatriate remuneration survey report captures the latest expatriate remuneration trends',
    },
  },
  {
    path: '/tools/group_surveys',
    name: 'Industry Club Group Customised Surveys',
    component: () => import('../views/tools/IndustryClubGroupCustomisedSurveys.vue'),
    meta: {
      title: 'Industry Club Group Customised Surveys',
      desc: 'Dedicated teams of consultants manage each survey professionally throughout the entire survey process',
    },
  },
  {
    path: '/tools/benefits_data_services',
    name: 'Benefits Data Services',
    component: () => import('../views/tools/BenefitsDataServices.vue'),
    meta: {
      title: 'Benefits Data Services',
      desc: 'MRC offers Employee Benefits Services (on demand) to provide information on market practices in a quantitative manner.',
    },
  },
  {
    path: '/tools/employment_legislation',
    name: 'Employment Legislation',
    component: () => import('../views/tools/EmploymentLegislation.vue'),
    meta: {
      title: 'Employment Legislation',
      desc: 'Access over 180 countries of employment legislations by Country',
    },
  },
  {
    path: '/tools/relocation_calculator',
    name: 'Relocation Calculator',
    component: () => import('../views/tools/RelocationCalculator.vue'),
    meta: {
      title: 'Relocation Calculator',
      desc: 'Stimulate mobility package for domestic / cross-border relocation – unlimited as desired for 10,000 cities',
    },
  },
  {
    path: '/tools/pay_trends',
    name: 'Pay Trends',
    component: () => import('../views/tools/PayTrends.vue'),
    meta: {
      title: 'Pay Trends',
      desc: 'Fresh graduates start rates, worldwide minimum wage and living wage by country/region/city',
    },
  },
  {
    path: '/tools/forecast_predictor',
    name: 'Forecast Predictor',
    component: () => import('../views/tools/ForecastPredictor.vue'),
    meta: {
      title: 'Forecast Predictor',
      desc: 'Salary increase forecast predictor for the next 5 years, by country and by industry',
    },
  },
  {
    path: '/tools/productivity_financials',
    name: 'Productivity Financials',
    component: () => import('../views/tools/ProductivityFinancials.vue'),
    meta: {
      title: 'Productivity Financials',
      desc: 'Access over 38,000 listed companies worldwide to benchmark against your target competitors’ productivity & financials',
    },
  },
  {
    path: '/tools/hr_market',
    name: 'HR Market',
    component: () => import('../views/tools/HRMarket.vue'),
    meta: {
      title: 'HR Market',
      desc: 'Job posting trends by job functions and hierarchies, HR Supply / Demand momentum',
    },
  },
  {
    path: '/tools/economy',
    name: 'Economy',
    component: () => import('../views/tools/Economy.vue'),
    meta: {
      title: 'Economy',
      desc: 'GDP size, growth, inflation, unemployment, investment, etc. by country worldwide',
    },
  },
  {
    path: '/tools/hr_cnb_company_news',
    name: 'C&N News',
    component: () => import('../views/tools/HRCnBCompanyNews.vue'),
    meta: {
      title: 'HR/C&B/Company News',
      desc: 'COVID-19, Hiring, Pay, Redundancy, Union, M&A, etc. by country, backed by data source, worldwide',
    },
  },
  {
    path: '/predictive',
    name: 'People Analytics',
    component: () => import(/* webpackChunkName: "website" */ '../views/Predictive.vue'),
    meta: {
      title: 'MRC - Data Science: People Analytics',
      desc: 'Predicting employee flight risk. HR metrics & predictive dashboards',
    },
  },
  {
    path: '/ai/employee_risk_predictor',
    name: 'Employee Risk Predictor',
    component: () => import('../views/ai/EmployeeRiskPredictor.vue'),
    meta: {
      title: 'MRC - AI Services - Employee Risk Predictor',
      desc: 'Predicting employee flight risk.',
    },
  },
  {
    path: '/ai/employee_salary_predictor',
    name: 'Employee Risk Predictor',
    component: () => import('../views/ai/EmployeeSalaryPredictor.vue'),
    meta: {
      title: 'MRC - AI Services - Employee Salary Predictor',
      desc: 'Predicting employee salary.',
    },
  },
  {
    path: '/ai/ai_content_generator',
    name: 'AI Content Generator',
    component: () => import('../views/ai/AIContentGenerator.vue'),
    meta: {
      title: 'MRC - AI Services - AI Content Generator',
      desc: 'AI Content Generator, leverage GPT model for HR use.',
    },
  },
  {
    path: '/ai/ai_others',
    name: 'MRC Other AI Services',
    component: () => import('../views/ai/AIOthers.vue'),
    meta: {
      title: 'MRC - AI Services - Others',
      desc: 'MRC Other AI Services.',
    },
  },
  {
    path: '/book',
    name: 'Social Responsibility',
    component: () => import(/* webpackChunkName: "website" */ '../views/SocialResponsibility.vue'),
    meta: {
      title: 'MRC - Social Responsibility',
      desc: 'Giving Up Salary and Donations',
    },
  },
  {
    path: '/mrc',
    name: 'About MRC-Asia',
    component: () => import(/* webpackChunkName: "website" */ '../views/About.vue'),
    meta: {
      title: 'MRC - About MRC',
      desc: 'About MRC',
    },
  },
  {
    path: '/online_services',
    name: 'Log in Online Services',
    component: () => import(/* webpackChunkName: "website" */ '../views/OnlineServices.vue'),
    meta: {
      title: 'MRC - Online Services',
      desc: 'Log in to MRC Online Services',
      requiresAuth: true,
    },
  },
  {
    path: '/online_services/shared',
    name: 'Log in Online Services',
    component: () => import(/* webpackChunkName: "website" */ '../views/OnlineServicesShared.vue'),
    meta: {
      title: 'MRC - Online Services',
      desc: 'Log in to MRC Online Services',
      requiresAuth: true,
    },
  },
  {
    path: '/references',
    name: 'MRC References',
    component: () => import(/* webpackChunkName: "website" */ '../views/DownloadCenter.vue'),
    meta: {
      title: 'MRC References',
      desc: 'MRC References',
      requiresAuth: true,
    },
  },
  {
    path: '/market_insights',
    name: 'C&B Market Insights',
    component: () => import(/* webpackChunkName: "website" */ '../views/MarketInsights.vue'),
    meta: {
      title: 'C&B Market Insights',
      desc: 'C&B Market Insights',
    },
  },
  {
    path: '/market_insights/ebi',
    component: () => import(/* webpackChunkName: "ebi" */ '../views/ebi/Ebi.vue'),
    children: [
      {
        name: 'EBI Listings',
        path: '',
        component: () => import(/* webpackChunkName: "ebi" */ '../views/ebi/EbiList.vue'),
        meta: {
          title: 'MRC - Executive Business Intelligence',
          desc: 'Executive Business Intelligence articles.',
        },
      },
      {
        name: 'EBI Article',
        path: ':articleId',
        component: () => import(/* webpackChunkName: "ebi" */ '../views/ebi/EbiArticle.vue'),
        props: true,
      },
    ],
  },
  {
    path: '/introduction/video',
    component: () => import(/* webpackChunkName: "ebi" */ '../views/videos/Video.vue'),
    children: [
      {
        name: 'Video View',
        path: ':videoId',
        component: () => import(/* webpackChunkName: "ebi" */ '../views/videos/VideoView.vue'),
        props: true,
      },
    ],
  },
  {
    path: '/seminar_members',
    name: 'Seminar Materials',
    component: () => import(/* webpackChunkName: "website" */ '../views/SeminarMember.vue'),
    meta: {
      title: 'MRC - Seminar Materials',
      desc: 'Seminars/Workshops Materials',
      requiresAuth: true,
    },
  },
  {
    path: '/seminars',
    name: 'Total Rewards Symposium / Seminars',
    component: () => import(/* webpackChunkName: "website" */ '../views/Seminars.vue'),
    meta: {
      title: 'MRC - Symposium/Seminars',
      desc: 'Total Rewards Symposium . Seminars',
    },
  },
  {
    path: '/market_insights_members',
    name: 'Market Insights members download',
    component: () => import(/* webpackChunkName: "website" */ '../views/MarketInsightsMember.vue'),
    meta: {
      title: 'MRC - Market Insights',
      desc: 'Market Insights',
      requiresAuth: true,
    },
  },
  {
    path: '/privacy',
    name: 'Data Privacy',
    component: () => import(/* webpackChunkName: "website" */ '../views/DataPrivacy.vue'),
    meta: {
      title: 'MRC - Data Privacy',
      desc: 'Data Privacy',
    },
  },
  {
    path: '/join',
    name: 'Grow with us',
    component: () => import(/* webpackChunkName: "website" */ '../views/Hiring.vue'),
    meta: {
      title: 'MRC - Grow with us',
      desc: 'Grow with us',
    },
  },
  { path: '*', component: () => import(/* webpackChunkName: "notfound" */ '../views/NotFound.vue') },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   if (to.path === '/') {
//     // next('/home?ignoreCache=1');
//     next('/home');
//     // window.location.reload();
//     // window.location.href = '/home';
//     return;
//   }
//   next();
// });

/* eslint-disable @typescript-eslint/no-non-null-assertion */
router.afterEach((to) => {
  window.scrollTo(0, 0);
  if (to.name === 'EBI Article' || to.name === 'Video View') {
    return;
  }
  document.querySelector('meta[property="og:url"]')!.setAttribute('content', `https://www.mrc-asia.com${to.fullPath}`);
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
    document.querySelector('meta[property="og:title"]')!.setAttribute('content', to.meta.title);
  }
  if (to.meta && to.meta.desc) {
    document.querySelector('meta[name="description"]')!.setAttribute('content', to.meta.desc);
    document.querySelector('meta[property="og:description"]')!.setAttribute('content', to.meta.desc);
  }
});

export default router;
